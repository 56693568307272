@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Exo', sans-serif !important;

  .welfare {
    background-color: #FFFFFF;
  }

  button {
    color: var(--white, #FFF);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }

  --font-family-sans-serif: 'Exo',
  sans-serif !important;
  --font-family-monospace: 'Exo',
  sans-serif !important;

  .side-bar-img-admin {
    object-fit: contain;
    width: 188.3px;
    height: 37.32px;
  }

  .MuiToolbar-regular {
    min-height: 56px !important;
  }

  .MuiAppBar-colorPrimary {
    background-color: #FFFFFF !important;

    .MuiPaper-elevation4 {
      box-shadow: none !important;
    }
  }
}

.status-active {
  border-radius: 4px;
  background: var(--utility-green, #14AE5C);
  width: 96px;
  padding: 7.5px 31.6px 7.8px 32.4px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.304px;
  /* 143% */
  letter-spacing: 0.15px;
  place-self: center;
  text-align: center;
}

.status-inactive {
  width: 96px;
  padding: 7.5px 31.6px 7.8px 32.4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--black-object-disable, #9E9E9E);
  color: #FFF;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.304px;
  /* 143% */
  letter-spacing: 0.15px;
  place-self: center;
  text-align: center;
}

.status-delete {}